<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                2023苏黎世-深圳数量金融风险夏令营，非凸联合创始人李佐凡担任特邀讲师
              </p>
              <div class="fst-italic mb-2">2023年8月11日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >校企合作</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/54/01-课堂.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    8月4日，由南方科技大学风险分析预测与管控研究院主办、苏黎世联邦理工学院和苏黎世大学支持、国泰君安证券作为首席合作伙伴的“2023年苏黎世-深圳数量金融风险夏令营”在深圳正式开营。非凸科技联合创始人&CTO李佐凡作为本次夏令营的特邀讲师之一，共同带领同学们了解国内外数量金融风险行业的前沿趋势，以及学习先进技术在该领域的应用和研究。
                  </p>
                  <p>
                    李佐凡老师在夏令营最后一天围绕算法交易，向同学们介绍了交易所的结构，各种委托类型和执行算法的优劣，以及要如何设计和选择一个更好的交易算法。而课程的重点部分开启于某只股票的走势图，李佐凡老师让同学们猜测接下来的涨跌趋势，并且现场与同学们头脑风暴，让每一位同学设计出符合自己想法以及真实交易的算法交易策略，同学们积极发言讨论出不同的方案，量化地用公式呈现，在下课前不断完善逻辑，在此过程中了解到，逐步加深对A股市场的交易规则，TWAP VWAP的定义等等的理解与认知。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/54/02-交流.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    在课间休息时，同学们也乐此不疲地紧握与李佐凡老师交流的时间。而在课程即将结束之际，同学们完善了算法交易方案，基本符合基础算法的概念，更令人意想不到的是，最开始举出某只股票的例子最终的结果与同学们猜测的相反，呈现出了上涨的趋势。也正是因为如此，在错综复杂的股市当中，同学们更加了解到AI智能交易的价值与意义。最后，李佐凡老师分享了他多年来在量化行业的实战经验和心得体会，为同学们指明了未来行业发展方向。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/54/03-证书.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    感谢南方科技大学的盛邀，非凸科技有幸加入讲师团队。在开营仪式上，南方科技大学风险院联席院长、讲席教授Didier Sornette院士表示“金融在全球经济体系中具有举足轻重的地位和作用，希望同学们把握本次夏令营与众多资深专家学者的深入交流，培养自身的批判性思维和专业能力。”
                  </p>
                  <p>
                    通过这一次的课堂，我们也深深感受到年青一代的表达能力与自我表现都很优秀，未来可期。非凸科技也将在关键技术创新、课题项目成果转化、人才培养等方面继续发力，旨在推动校企之间更丰富、更深层的合作，为同学们提供实践、探索的空间和条件，从而实现校企共赢新局面。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News54",
};
</script>

<style></style>
